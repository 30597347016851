<template>
  <div>
    <div class="w-full" v-if="!isPay">
      <div class="form-group">
        <label class="control-label vs-input--label">{{$t('marketing_place.payment_type')}}</label>
        <div class="mb-1 mt-2">
          <vs-radio v-model="paymentType"
                    vs-name="paymentType"
                    v-for="item in paymentTypeList"
                    :key="item.key"
                    :label="item.label"
                    :vs-value="item.key"
                    class="mr-2">{{item.label}}</vs-radio>
        </div>
        <div class="mb-1 mt-2" v-if="paymentType == 'CA'">
          <PayCard/>
        </div>
      </div>
    </div>
    <div class="w-full pt-5">
      <vs-divider />
      <div class="flex items-center justify-between">
        <span class="text-md">{{$t('marketing_place.total_value_')}}</span><span class="font-bold">{{formatValue(value_total)}}</span>
      </div>
      <vs-divider />
    </div>

    <div class="flex mt-4 mb-4 justify-end" v-if="!isPay">
      <vs-button
        :disabled="!validateForm"
        type="filled"
        icon="money"
        @click="confirmPayment()">{{ $t('action.confirm_payment') }}
      </vs-button>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { currencyFormatter } from '@/util/Util'

import CartService from '@/services/api/CartService'
import OrderService from '@/services/api/OrderService'
import PaymentService from '@/services/api/PaymentService'

import PayCard from '@/components/finance/PayCard'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    PayCard,

  },
  props: {
    order_id: {
      default: null,
      type: Number
    },
    status: {
      default: null,
      type: String
    },
    value_total: {
      default: 0,
      type: Number,
      required: true
    },
  },

  data: () => ({
    paymentTypeList: [],
    paymentType : '',
    cardFields: {
      cardName: '',
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
    },
    service: null,
    orderService: null,
    paymentService: null
  }),
  computed: {
    validateForm() {
      let validated = !this.isEmpty(this.paymentType)

      if(validated){
        switch (this.paymentType) {
          case 'CA': //Carton
            validated = (!this.isEmpty(this.cardFields.cardName)
                        && !this.isEmpty(this.cardFields.cardNumber)
                        && !this.isEmpty(this.cardFields.cardMonth)
                        && !this.isEmpty(this.cardFields.cardYear)
                        && !this.isEmpty(this.cardFields.cardCvv)
                      )
            break;

          case 'PI': //Pix
            validated = !this.isEmpty();
            break;
          default:
            break;
        }
      }

      return validated;
    },
    isPay(){
      return (this.status !==  null && this.status.key !== 'PA')
    }
  },
  beforeMount() {
    this.service = CartService.build(this.$vs)
    this.orderService = OrderService.build(this.$vs)
    this.paymentService = PaymentService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    loadData() {
      this.loadPaymentType()

    },
    loadPaymentType(){
      this.$vs.loading()
      this.paymentService.getPaymentTypesExternal().then(
        data => {
          this.paymentTypeList = data
        },
        error => {
          this.showErrors(this.$validator, error)
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    confirmPayment() {
      this.$vs.loading()

      const modelData = {
        payment_type: this.model.paymentType,
      }

      this.orderService.payOrder(this.order_id, {form : modelData}).then(
        data => {

          this.notifySuccess(this.$vs, this.$t('order.msg.pay_success'))
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('order.msg.pay_error'), 5000)
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    updatePaymentHandling(){
      this.$emit('updatePaymentHandling')
    },
    isAdmin() {
      return isAdmin()
    }
  }
}
</script>

<style>

</style>
