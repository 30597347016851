<template>
  <div>
    <vs-tabs :key="tabsCorporateSalesKey">
      <vs-tab :label="$t('marketing_place.purchase_summary')">
        <CartResume :cartItems="order.items"/>
          <div class="w-full flex gap-2 justify-end">
            <vs-button
              type="border"
              @click="goBack">
              {{ $t('common.back') }}
            </vs-button>
          </div>
      </vs-tab>
      <vs-tab :label="$t('marketing_place.payment')">
        <Checkout :order_id="orderId" :value_total="valueTotal"></Checkout>
        <div class="w-full flex gap-2 justify-end">
          <vs-button
            type="border"
            @click="goBack">
            {{ $t('common.back') }}
          </vs-button>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import CartResume from '../store/CartResume'
import Checkout from '../store/Checkout'

import OrderService from '@/services/api/OrderService'

import { currencyFormatter } from '@/util/Util'

export default {
  components: {
    CartResume,
    Checkout
  },
  props: {
    id: {
      default: null,
      type: Number
    },
  },
  data: () => ({
    order: {
      items: []
    },
    //Services
    service: null,
  }),
  watch: {

  },
  computed: {
    valueTotal () {
        let total = 0
      this.order.items.forEach((item) => {
            total += (item.quantity*item.price)
        });
        return total
    },
  },
  methods: {
    loadData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        response => {
          if(response){
            this.order = response
          }
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(() => {this.$vs.loading.close()})
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    goBack() {
      this.$router.push('/orders')
    },
  },
  beforeMount() {
    this.service = OrderService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
}
</script>
