import Rest from '../Rest'

export default class CartService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/payments'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getPaymentTypes() {
    return this.get(`/payment_types`).then(data => {
      return data
    })
  }

  getPaymentTypesExternal() {
    return this.get(`/payment_types/external`).then(data => {
      return data
    })
  }

}
