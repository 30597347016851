<template>
  <div>
    <!--<vue-paycard :valueFields="valueFields" :inputFields="inputFields"  />-->
  </div>
</template>
<script>

//import { VuePaycard } from "vue-paycard";

export default {
  directives: {

  },
  components: {
   // VuePaycard

  },
  props: {

  },
  data: () => ({
    valueFields : {
      cardName : 'reerererer',
      cardNumber : '5454 5454 5454 5545',
      cardMonth : '03',
      cardYear : null,
      cardCvv : '3434',
      },
      inputFields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      }

  }),
  computed: {

  },
  beforeMount() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style>

</style>
